<template>
  <div>
    <location />
    <div class="page wrap-1200">
      <h3 class="subtitle">{{ $t("text.event.main_title_1") }}</h3>
      <div class="row g-2 g-md-5">
        <div class="col-md-7">
          <p>
            {{ $t("text.event.main_sub_title_11") }}
          </p>
          <p>
            {{ $t("text.event.main_sub_title_12") }}
          </p>
          <!--
          <p>
            {{ $t("text.event.main_sub_title_13") }}
          </p>
          -->
          <div class="row row-cols-1 g-2 my-4 lh-sm text-black">
            <div class="col">
              {{ $t("text.event.main_sub_title_14") }}
            </div>
            <div class="col">
              {{ $t("text.event.main_sub_title_15") }}
            </div>
            <div class="col">
              {{ $t("text.event.main_sub_title_16") }}
            </div>
            <div class="col">
              {{ $t("text.event.main_sub_title_17") }}
            </div>
            <div class="col">
              {{ $t("text.event.main_sub_title_18") }}
            </div>
            <div class="col">
              {{ $t("text.event.main_sub_title_19") }}
            </div>
          </div>
          <TermDefinition getname="event" />
          <p class="mt-4">
            {{ $t("text.event.main_sub_title_20") }}
          </p>
        </div>
        <div class="col-md-5">
          <img src="/img/minigame_shot1.jpg" class="img-fluid">
        </div>
      </div>

      <hr class="my-5">

      <h3 class="subtitle">{{ $t("text.event.main_title_2") }}</h3>
      <div class="row g-2 g-md-5">
        <div class="col-md-7">
          <p>
            {{ $t("text.event.main_sub_title_25") }}
          </p>
          <p>
            {{ $t("text.event.main_sub_title_26") }}
          </p>
          <p>
            {{ $t("text.event.main_sub_title_27") }}
          </p>
        </div>
        <div class="col-md-5">
          <img src="/img/minigame_shot2.jpg" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import TermDefinition from "@/components/TermDefinition";
export default {
  name: "JoinMinigame",
  components: {
    location,
    TermDefinition,
  },
};
</script>