<template>
  <div>
    <location />

    <div class="swing-view page">
      <div class="page">
        <div class="wrap-1200">
          <div class="d-flex flex-column mb-4">
            <h3>
              {{ swingview.title }}
            </h3>
            <p class="text-date">
              <i class="material-icons mt-n1px">date_range</i>
              {{ swingview.regdate | GMTtuUTC }}
            </p>
          </div>

          <div class="box">
            <div class="ratio-16x9">
              <video
                :src="swingview.path + '/' + swingview.swingmovie"
                class="d-block w-100"
                muted
                controls
                autoplay
              ></video>
            </div>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <b-collapse id="collapseOne" v-model="visible">
                  <div class="accordion-body">
                    <div class="row g-0">
                      <div class="col-lg-3 row g-0 border-bottom">
                        <div class="col-lg bg-gray row g-0 align-items-center">
                          <label class="d-table-cell">{{
                            $t("title.membership.club_type")
                          }}</label>
                        </div>
                        <div class="col-lg">
                          <p class="d-table-cell">{{ swingview.club }}</p>
                        </div>
                      </div>

                      <div class="col-lg-3 row g-0 border-bottom">
                        <div class="col-lg bg-gray row g-0 align-items-center">
                          <label class="d-table-cell">{{
                            $t("title.membership.ball_speed")
                          }}</label>
                        </div>
                        <div class="col-lg">
                          <p class="d-table-cell">
                            {{ swingview.ballspeed | comma }} km/h
                          </p>
                        </div>
                      </div>

                      <div class="col-lg-3 row g-0 border-bottom">
                        <div class="col-lg bg-gray row g-0 align-items-center">
                          <label class="d-table-cell">{{
                            $t("title.membership.backspin")
                          }}</label>
                        </div>
                        <div class="col-lg">
                          <p class="d-table-cell">
                            {{ swingview.backspin | comma }} rpm
                          </p>
                        </div>
                      </div>

                      <div class="col-lg-3 row g-0 border-bottom">
                        <div class="col-lg bg-gray row g-0 align-items-center">
                          <label class="d-table-cell">{{
                            $t("title.membership.club_speed")
                          }}</label>
                        </div>
                        <div class="col-lg">
                          <p class="d-table-cell">
                            {{ swingview.carry | comma }}m
                          </p>
                        </div>
                      </div>

                      <div class="col-lg-3 row g-0 border-bottom">
                        <div class="col-lg bg-gray row g-0 align-items-center">
                          <label class="d-table-cell"
                            >Club Speed{{ $t("") }}</label
                          >
                        </div>
                        <div class="col-lg">
                          <p class="d-table-cell">
                            {{ swingview.clubspeed | comma }} km/h
                          </p>
                        </div>
                      </div>

                      <div class="col-lg-3 row g-0 border-bottom">
                        <div class="col-lg bg-gray row g-0 align-items-center">
                          <label class="d-table-cell">{{
                            $t("title.membership.sidespin")
                          }}</label>
                        </div>
                        <div class="col-lg">
                          <p class="d-table-cell">
                            {{ swingview.balldirection | comma }} deg
                          </p>
                        </div>
                      </div>

                      <div class="col-lg-3 row g-0 border-bottom">
                        <div class="col-lg bg-gray row g-0 align-items-center">
                          <label class="d-table-cell"
                            >Side Spin{{ $t("") }}</label
                          >
                        </div>
                        <div class="col-lg">
                          <p class="d-table-cell">
                            {{ swingview.sidespin | comma }} rpm
                          </p>
                        </div>
                      </div>

                      <div class="col-lg-3 row g-0 border-bottom">
                        <div class="col-lg bg-gray row g-0 align-items-center">
                          <label class="d-table-cell">{{
                            $t("title.membership.distance")
                          }}</label>
                        </div>
                        <div class="col-lg">
                          <p class="d-table-cell">
                            {{ swingview.distance | comma }}m
                          </p>
                        </div>
                      </div>

                      <div class="col-lg-3 row g-0 border-bottom">
                        <div class="col-lg bg-gray row g-0 align-items-center">
                          <label class="d-table-cell">{{
                            $t("title.membership.club_angle")
                          }}</label>
                        </div>
                        <div class="col-lg">
                          <p class="d-table-cell">
                            {{ swingview.clubnagel | comma }} deg
                          </p>
                        </div>
                      </div>

                      <div class="col-lg-3 row g-0 border-bottom">
                        <div class="col-lg bg-gray row g-0 align-items-center">
                          <label class="d-table-cell">{{
                            $t("title.membership.launch_angle")
                          }}</label>
                        </div>
                        <div class="col-lg">
                          <p class="d-table-cell">
                            {{ swingview.launchangle | comma }} deg
                          </p>
                        </div>
                      </div>

                      <div class="col-lg-3 row g-0 border-bottom">
                        <div class="col-lg bg-gray row g-0 align-items-center">
                          <label class="d-table-cell">{{
                            $t("title.membership.ball_pitch")
                          }}</label>
                        </div>
                        <div class="col-lg">
                          <p class="d-table-cell">
                            {{ change_balltype(swingview.balltype) }}
                          </p>
                        </div>
                      </div>

                      <div class="col-lg-3 row g-0 border-bottom">
                        <div class="col-lg bg-gray row g-0 align-items-center">
                          <label class="d-table-cell">{{
                            $t("title.membership.tee_height")
                          }}</label>
                        </div>
                        <div class="col-lg">
                          <p class="d-table-cell">
                            {{ swingview.teeheight | comma }}mm
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
                <div class="row g-0">
                  <div class="col-lg-3 d-table m-auto">
                    <h2 class="accordion-header" id="headingOne">
                      <b-button
                        class="accordion-button align-center"
                        :class="visible ? null : 'collapsed'"
                        :aria-expanded="visible ? 'true' : 'false'"
                        aria-controls="collapseOne"
                        variant="primary"
                        @click="visible = !visible"
                      >
                        {{ $t("button.common.swing_info_view") }}
                      </b-button>
                    </h2>
                  </div>
                </div>
              </div>
              <p class="content">
                {{ swingview.content }}
              </p>
              <div class="rebox">
                <b-form @submit="onSubmit">
                  <div class="border-bottom comment">
                    <p>
                      {{ $t("title.common.comment") }}
                      (<strong class="text-orange">{{
                        this.swingreplylistcnt
                      }}</strong
                      >)
                    </p>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Type your message."
                        v-model="form.text"
                      />
                      <button
                        class="btn btn-primary btn-md position-relative"
                        type="submit"
                      >
                        <span
                          class="material-icons fs-14 position-absolute top-50 start-50 translate-middle"
                          >send</span
                        >
                      </button>
                    </div>
                  </div>
                </b-form>

                <div
                  class="border-bottom reply"
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <div>
                    <div class="d-flex flex-row">
                      <div>
                        <img
                          :src="item.member_photo_url | get_img"
                          class="center-cropped"
                        />
                      </div>

                      <div class="align-self-center">
                        <h6>{{ item.nickname }}</h6>
                        <p class="text-date">
                          <i class="material-icons">schedule</i>{{ item.date }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="balloon text-break">
                      {{ item.text }}
                      <a
                        class="del position-absolute top-0 start-100 translate-middle"
                        v-if="item.register_fk === pk"
                        @click="del_reply(item.replyno)"
                      >
                        <i class="material-icons">cancel</i>
                      </a>
                    </div>
                  </div>
                </div>
                <!-- //reply -->
              </div>
              <!-- //rebox -->
            </div>
            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="page_total"
              v-model="pageno"
              use-router
              aria-label="Page navigation"
              v-if="page_total > 1"
              first-class="prev"
              prev-class="prev"
              next-class="next"
              last-class="next"
              hide-ellipsis
              limit="10"
            >
              <template #first-text>
                <i class="material-icons">keyboard_double_arrow_left</i>
              </template>
              <template #prev-text>
                <i class="material-icons">navigate_before</i>
              </template>
              <template #next-text>
                <i class="material-icons">navigate_next</i>
              </template>
              <template #last-text>
                <i class="material-icons">keyboard_double_arrow_right</i>
              </template>
              <template #page="{ page, active }">
                <a v-if="active">{{ page }}</a>
                <a v-else>{{ page }}</a>
              </template>
            </b-pagination-nav>

            <div class="btn-bottom-wrapper">
              <b-button
                size="xl"
                variant="outline-primary"
                :aria-label="$t('button.common.list')"
                @click="
                  $router.push({
                    name: 'SwingList',
                    query: {
                      searchtype: $route.query.searchtype,
                      searchname: $route.query.searchname,
                    },
                  })
                "
              >
                {{ $t("button.common.list") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import location from "@/components/Location";
import ax from "@/api/event";
import "@/filter/common";

export default {
  name: "netizenLessonView",
  components: {
    location,
  },
  data() {
    return {
      idx: this.$route.params.id,
      visible: false,
      swingview: [],
      pageno: parseInt(this.$route.query.page) || 1,
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      swingreplylistcnt: 0,
      form: {
        text: "",
      },
      token: sessionStorage.getItem("token") || false,
      pk: sessionStorage.getItem("pk") || 0,
      VUE_APP_S3_BUCKET_URL: process.env.VUE_APP_S3_BUCKET_URL,
    };
  },
  methods: {
    get_swingview() {
      ax.get_swingview(this.idx, (flag, data) => {
        if (flag) {
          this.swingview = data.swingview;
        } else {
          alert(data);
        }
      });
    },
    change_balltype(type) {
      if (type == 1) return this.$i18n.t("text.common.straight");
      else if (type == 2) return this.$i18n.t("text.common.hook");
      else if (type == 3) return this.$i18n.t("text.common.slice");
      else if (type == 4) return this.$i18n.t("text.common.pull_straight");
      else if (type == 5) return this.$i18n.t("text.common.pull_hook");
      else if (type == 6) return this.$i18n.t("text.common.pull_slice");
      else if (type == 7) return this.$i18n.t("text.common.push_straight");
      else if (type == 8) return this.$i18n.t("text.common.push_hook");
      else if (type == 9) return this.$i18n.t("text.common.push_slice");
      else return "-";
    },
    get_swingreplylist() {
      ax.get_swingreplylist(this.idx, this.pageno, (flag, data) => {
        if (flag) {
          if (data.swingreplylistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.swingreplylistcnt;
          this.page_total = Math.ceil(data.swingreplylistcnt / this.rows);
          this.items = data.swingreplylist;
          this.swingreplylistcnt = data.swingreplylistcnt;
        } else {
          this.data_yn = false;
          this.page_total = 0;
          this.items = [];
          this.swingreplylistcnt = 0;
        }
      });
    },
    del_reply(replayno) {
      ax.set_swingreplydrop(this.idx, replayno, (flag, data) => {
        if (flag) {
          if (this.pageno > 1) this.$router.push({ query: { page: 1 } });
          else this.get_swingreplylist();
        } else {
          alert(data);
        }
      });
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    onSubmit(event) {
      event.preventDefault();
      // if (!this.token) {
      //   alert("로그인 후에 댓글 작성이 가능합니다.");
      //   return false;
      // }
      ax.set_swingreplywrite(this.idx, this.form.text, (flag, data) => {
        if (flag) {
          this.form.text = "";
          this.get_swingreplylist();
        } else {
          alert(data);
        }
      });
    },
  },
  created() {
    this.get_swingview();
    this.get_swingreplylist();
  },
  watch: {
    "$route.query": function () {
      this.pageno = parseInt(this.$route.query.page) || 1;
      this.get_swingreplylist();
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
